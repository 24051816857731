import * as React from "react";
import "./BannerEmpleos.css";
import {useEffect} from "react";
import ImgBanner  from '../../../../../images/banner/bg-busquedas.jpg'

const ProductBannerNoLine = () => {
  const headingType = 'h1';
  const bannerContainerClassName = "bannerReporte";
  const textContainerClassName = "textContainer";
  const bannerText = `<strong>Completá el siguiente formulario</strong> <br/> para participar de la búsqueda laboral`;
  const bannerTextClassName = "bannerTextInversiones";
  const bannerTrasparecy = "bannerMask-noblue";
  const bannerImage = ImgBanner;
  const bannerImageMobile = ImgBanner;
  const bannerImageClassName = "ReportBannerImage";
  const productTitle = "<strong>Oportunidades</strong> <br> búsquedas abiertas";
  const productTitleBlueClassName = "productTitleEmpleos";
  const productTitleClassName = "";
  const mobile = false;
  const bannerDesc = null;

  const CustomTag = `${headingType}`;
  let textmod;
  let breakindicator;

  if (bannerText) {
    if (bannerText.includes(`\n`) || bannerText.includes(`\r`)) {
      if (bannerText.includes(`\n`)) {
        breakindicator = '\n';
      } else if (bannerText.includes(`\r`)) {
        breakindicator = '\r';
      }

      textmod = bannerText.split(breakindicator).map(function (item, index) {
        if (item.includes(`span`)) {
          let textArray = item.split(`span`);
          return (
            <p key={index}>
              {textArray[0]}
              <span className='highlightsbold'>{textArray[1]}</span>
            </p>
          );
        }
        return (
          <p key={index}>
            {item}
          </p>
        )
        if (breakindicator == '\r') {
          return (
            <p key={index}>
              {item}
            </p>
          )
        } else {
          return (
            <p key={index}>
              {item}<br />
            </p>
          )
        }
      });
    } else {
      if (bannerText.includes(`span`)) {
        let textArray = bannerText.split(`span`);
        textmod = (
          <p>
            {textArray[0]}{" "}
            <span className='highlightsbold'>
              {textArray[1]}
            </span>
          </p>
        );
      } else {
        textmod = bannerText;
      }
    }
  }

  useEffect(() => {
    const elements = document.querySelectorAll('.last-line');
    const nameElement = 'last-line-element';
    const nameRow = 'last-line-row';

    const wrapAll = (nodes, wrapper, elem) => {
      const parent = nodes[0].parentNode;
      const { previousSibling } = nodes[0];
      for (let i = 0; nodes.length - i; wrapper.firstChild === nodes[0] && (i += 1)) {
        wrapper.appendChild(nodes[i]);
      }
      if (previousSibling) {
        parent.insertBefore(wrapper, previousSibling.nextSibling);
      } else {
        elem.appendChild(wrapper);
      }
      return wrapper;
    };

    const findLastRow = (elem) => {
      const content = elem.innerText.trim();
      const contentArr = content.split(' ');
      let contentFormatted = '';
      contentArr.forEach((item) => {
        contentFormatted += `<span>${item} </span>`;
      });
      const element = elem;
      element.innerHTML = contentFormatted;

      const childrenSpan = Array.from(elem.getElementsByTagName('span'));
      let top = 0;
      childrenSpan.forEach((item) => {
        const thisTop = item.offsetTop;
        if (thisTop === top) {
          item.classList.add(nameElement);
        } else {
          childrenSpan.forEach((el) => {
            el.classList.remove(nameElement);
          });
          top = thisTop;
          item.classList.add(nameElement);
        }
      });

      const wrapElements = element.querySelectorAll(`.${nameElement}`);
      const wrapper = document.createElement('span');
      wrapper.classList.add(nameRow);
      wrapAll(wrapElements, wrapper, elem);
    };

    const findLastRows = () => {
      elements.forEach((elem) => {
        findLastRow(elem);
      });
    };

    window.addEventListener('resize', () => {
      findLastRows();
    });
    findLastRows();
  }, []);

  return (
    <div className={`productBanner ${bannerContainerClassName}`}>
      <div className={`bannerImage ${bannerTrasparecy}`} style={
        mobile
          ? { backgroundImage: `url(${bannerImageMobile})`, backgroundSize: "cover", backgroundPosition: "right" }
          : { backgroundImage: `url(${bannerImage})`, backgroundSize: "cover", backgroundPosition: "right" }
      }>
        <div className="container">
          {null !== bannerDesc ? (
            <div className="bannerTitleContainer">
              <CustomTag className={`productTitle ${productTitleClassName} ${productTitleBlueClassName}`}>
                <div dangerouslySetInnerHTML={{ __html: productTitle }} />
              </CustomTag>
              <div dangerouslySetInnerHTML={{ __html: bannerDesc }} />
            </div>
          ) : (
            <CustomTag className={`productTitle ${productTitleClassName} ${productTitleBlueClassName}`}>
              <div dangerouslySetInnerHTML={{ __html: productTitle }} />
            </CustomTag>
          )}
        </div>
      </div>
      {bannerText && (
        <div className={`${textContainerClassName}`}>
          <div className="container">
            <div className={`bannerText ${bannerTextClassName}`}>
              <p className="animate__animated animate__fadeInUp" dangerouslySetInnerHTML={{ __html: bannerText }} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductBannerNoLine;