
export const API_URL = "https://balanz-rrhh.development-xyclon.com/api/v1";
export const TOKEN_KEY = 'BLNZ_TOKEN';
export const PRODUCTION_URL = "https://balanz-rrhh.development-xyclon.com";

export const ROLE_ADMIN = 1;
export const ROLE_HR = 2;
export const ROLE_DIRECTOR = 3;
export const ROLE_HIM = 4;
export const ROLE_PSYCH = 5;
export const ROLE_DIRECTOR_RRHH = 6;
export const ROLE_HIM_RRHH = 7;

export const Psych = [ROLE_PSYCH, ROLE_HR, ROLE_ADMIN, ROLE_DIRECTOR_RRHH, ROLE_HIM_RRHH];
export const Director = [ROLE_DIRECTOR, ROLE_HR, ROLE_ADMIN, ROLE_DIRECTOR_RRHH, ROLE_HIM_RRHH];
export const Manager = [ROLE_HIM, ROLE_HIM_RRHH, ROLE_DIRECTOR, ROLE_DIRECTOR_RRHH, ROLE_HR, ROLE_ADMIN];
export const Analyst = [ROLE_HR, ROLE_ADMIN, ROLE_DIRECTOR_RRHH, ROLE_HIM_RRHH];
export const Admin = [ROLE_ADMIN];
export const RRHH = [ROLE_HR, ROLE_DIRECTOR_RRHH, ROLE_HIM_RRHH];
export const RoleAll = [ROLE_HIM, ROLE_HIM_RRHH, ROLE_PSYCH, ROLE_DIRECTOR, ROLE_HR, ROLE_ADMIN, ROLE_DIRECTOR_RRHH];
export const ApproveRRHH = [ROLE_HR, ROLE_HIM_RRHH];
export const ApproveDIRECTOR = [ROLE_DIRECTOR, ROLE_DIRECTOR_RRHH];

// Permissions to see, edit and not see

export const NOT_SEE = 0;
export const SEE = 1;
export const EDIT = 2;

export const FILE_SIZE_LIMIT = 25

// PASA A BUSQUEDA STATUS
export const TO_SEARCH = 6 