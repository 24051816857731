import React, { useEffect, useState, useMemo } from "react";
import { Button, Form, Modal, Spinner, Alert } from 'react-bootstrap';
import styled from "styled-components";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  usePagination,
  useSortBy,
} from "react-table";
import Footer from "../../includes/Footer/Footer";
import Header from "../../includes/Header/Header";
import Menu from "../../includes/Menu/Menu";
import "./ABMUser.css";
import { API } from "../../../http-common";
import derecha from "../../../images/menu/derecha.png"
import LoadingList from "../Loading/LoadingList";
import '.././../ModalGenericoBalanz/ModalBalanz.css'
import editar from "../../../images/menu/editarblack.png";
import trash from "../../../images/menu/delete.png"

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <input
      className="form-control search"
      value={value || ""}
      onChange={(e) => {
        setValue(e.target.value);
        onChange(e.target.value);
      }}
      placeholder={`Buscar`}
    />
  );
}

const Styles = styled.div`
  display: block;
  max-width: 100%;
  .tableWrap {
    display: block;
    max-width: 100%;
    overflow-y: hidden;
    border-bottom: 1px solid black;
  }
  table {
    width: 100%;
    border-spacing: 0;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;
      width: 1%;
      &.collapse {
        width: 0.0000000001%;
      }
      :last-child {
        border-right: 0;
      }
    }
  }
  .pagination {
    padding: 0.5rem;
  }
`;

function Table({ columns, data, setShowModal, setShowModalConfirm }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    preGlobalFilteredRows,
    setGlobalFilter,
    state,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, globalFilter },
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <Styles>
      <div className="tableWrap">
        <div className="d-flex">
          <div className="botones">
            <button type="button" onClick={() => {
              setShowModal(true)
            }}>Alta de Empresa</button>
          </div>
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        </div>
        <table className="table table_busqueda" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render('Header')}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? ' 🔽'
                          : ' 🔼'
                        : ''}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <ul className="pagination">
          <li
            className="page-item"
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            <a className="page-link">{"Primera"}</a>
          </li>
          <li
            className="page-item"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            <a className="page-link">{"< Anterior"}</a>
          </li>
          <li
            className="page-item"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            <a className="page-link">{"Siguiente >"}</a>
          </li>
          <li
            className="page-item"
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            <a className="page-link">{"Ultimo"}</a>
          </li>
          <li>
            <a className="page-link no-hover">
              Pagina{" "}
              <strong>
                {pageIndex + 1} de {pageCount}
              </strong>{" "}
            </a>
          </li>
          <li className="page-item">
            <span>
              <input
                className="page-link no-hover"
                type="number"
                defaultValue={pageIndex + 1}
                min="1"
                max={pageCount}
                value={pageIndex + 1}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  gotoPage(page);
                }}
                style={{ width: "80px", textAlign: "center" }}
              />
            </span>
          </li>{" "}
          <select
            className="page-link no-hover"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
            style={{ fontSize: "15px", width: "120px", padding: "7px" }}
          >
            {[5, 10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Mostrar {pageSize}
              </option>
            ))}
          </select>
        </ul>
      </div>
    </Styles>
  );
}

const ABMCompanies = () => {
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [isLoadingTable, setIsLoadingTable] = useState(false);
  const [currentItem, setCurrentItem] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [error, setError] = useState(null);

  const columns = useMemo(
    () => [
      {
        accessor: 'name',
      },
      {
        accessor: 'country',
      },
      {
        Header: 'Acciones',
        Cell: ({ row }) => (
          <>
            <closeButton size="lg" onClick={() => handleEdit(row.original)}>
             <img
              style={{
                margin: "0 0 0 0",
                
              }}
              width={20}
              src={editar}
              alt="pen"
             />
            </closeButton>{' '}
            
            <Button 
              style={{
                backgroundColor: "transparent",
                border: "none",
              }}
            size="sm" onClick={() => handleDelete(row.original.id)}>
              <img
              style={{
                margin: "0 0 0 0",
              }}
              width={20}
              src={trash}
              alt="trash"
              />
            </Button>
          </>
        ),
      },
    ],
    []
  );

  const handleCreate = () => {
    setIsEditing(false);
    setCurrentItem({});
    setShowModal(true);
  };

  const handleEdit = (item) => {
    setIsEditing(true);
    setCurrentItem({ ...item });
    setShowModal(true);
  };

  const handleDelete = (id) => {
    setCurrentItem({ id });
    setShowModalConfirm(true);
  };
  

  const confirmDelete = async () => {
    setLoading(true);
    try {
      await API.post(`/companies/delete/${currentItem.id}`);
      setData(data.filter((item) => item.id !== currentItem.id));
      setShowModalConfirm(false);
      getCompanies();
    } catch (error) {
      console.error('Error deleting company:', error);
      setError('Error deleting company');
    } finally {
      setLoading(false);
    }
  };

  const getCompanies = async () => {
    setIsLoadingTable(true);
    try {
      const response = await API.get("/companies");
      let arr = [];
      response.data.data.map((item) => {
        arr.push({ id: item.id, name: item.name });
      });
      console.log(arr)
      setData(arr);
    } catch (error) {
      console.error(error);
      setError('Error fetching companies');
    } finally {
      setIsLoadingTable(false);
    }
  }
  const handleSave = async () => {
    setLoading(true);
    try {
      if (isEditing) {
        await API.post(`/companies/update/${currentItem.id}`, currentItem);
        setData(
          data.map((item) =>
            item.id === currentItem.id ? currentItem : item
          )
        );
      } else {
        const response = await API.post('/companies/create', currentItem);
        setData([
          ...data,
          { ...currentItem, id: response.data.id },
        ]);
      }
      setShowModal(false);
      getCompanies();
    } catch (error) {
      console.error('Error al actualizar la empresa:', error);
      setError(error.response?.data?.error || 'Error al actualizar la empresa');
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    getCompanies();
  }, []);
  return (
    <>
      <Header />
      <Menu />
      <div className="content-candidatos">
        <div className="contenido">
          <div className="content-table">
            {isLoadingTable ? (
              <LoadingList />
            ) : (
              <Table columns={columns} data={data} setShowModal={setShowModal} setShowModalConfirm={setShowModalConfirm} />
            )}
          </div>
        </div>
      </div>
      <Footer />

      <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>{isEditing ? 'Editar' : 'Agregar'} Empresa</Modal.Title>
          </Modal.Header>
          <Modal.Body>
  {loading ? (
    <Spinner
     animation="border"
     style={{ 
      margin: '20px auto', 
      display: 'flex',
      width: '5rem',
      height: '5rem',
    }} 

     />
  ) : (
    <Form>
      <Form.Group controlId="formBasicEmail">
        <Form.Label>Name</Form.Label>
        <Form.Control
          type="text"
          placeholder='Nombre de la Empresa'
          value={currentItem.name || ''}
          onChange={(e) =>
            setCurrentItem({ ...currentItem, name: e.target.value })
          }
        />
      </Form.Group>
      <Form.Group controlId="formBasicCountry">
                  <Form.Label>Country</Form.Label>
                  <Form.Control
                    as="select"
                    value={currentItem.country || ''}
                    onChange={(e) =>
                      setCurrentItem({ ...currentItem, country: e.target.value })
                    }
                  >
                    <option value="">Seleccione un país</option>
                    <option value="1011">Argentina</option>
                    <option value="1237">Uruguay</option>
                  </Form.Control>
                </Form.Group>
    </Form>
  )}

 {
  !loading &&  error && <Alert variant="danger">{error}</Alert>
 }
</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}> Cancelar
            </Button>
            <Button variant="primary" onClick={handleSave}> Guardar
            </Button>
          </Modal.Footer>
        </Modal>


        /*
        * Modal de confirmación
        */
      <Modal show={showModalConfirm} onHide={() => setShowModalConfirm(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Eliminar Empresa</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            loading ? (
              <Spinner 
              animation="border" 
              style={{ 
                margin: '20px auto', 
                display: 'flex',
                width: '4rem',
                height: '4rem',
              }} 
              />
            ) : (
              <p>¿Estás seguro que deseas eliminar la Empresa?</p>
            )
          }
          {
            !loading && error && <Alert variant="danger">{error}</Alert>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModalConfirm(false)}>Cancelar</Button>
          <Button variant="primary" onClick={confirmDelete}>Eliminar</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ABMCompanies;

