
import {  Container, Row, Col } from 'react-bootstrap'
import './busqueda.css'
import ProductBannerNoLine from './BannerEmpleos/BannerEmpleos'
import { ReactComponent as LogoBalanzSVG } from '../../../../images/Logo/logo_Balanz.svg'
const EmpleosDetail = ({position, description}) => {
    return (
        <div className="min-h-screen bg-white">
            <header className="header-empleos text-white p-4">
                <Container fluid className="px-4">
                    <Row className="align-items-center">
                        <Col>
                            <LogoBalanzSVG 
                                width={144}
                                height={28}
                            />
                        </Col>
                    </Row>
                </Container>
            </header>
            <ProductBannerNoLine />
           <Container  className="px-4 py-5">
           <div className="mb-8">
              <h3 className="text-xl font-semibold mb-2 productTitleEmpleos">Posición:</h3>
              <p 
              style={{fontSize:"2rem"}}
              >
                {position}</p>
            </div>
            <div className="mb-8">
              <h3 className="text-xl font-semibold mb-2 productTitleEmpleos">Descripción:</h3>
              <p
               style={{fontSize:"2rem"}}
              >{description}</p>
            </div>
         </Container>
          
        </div>
    );
};

export default EmpleosDetail;